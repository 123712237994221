import { render, staticRenderFns } from "./PZD801_DeviceConfig.vue?vue&type=template&id=85a967ea&"
import script from "./PZD801_DeviceConfig.vue?vue&type=script&lang=js&"
export * from "./PZD801_DeviceConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports