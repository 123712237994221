<template>
  <div>
    序列号:<el-input v-model="searchObj.onlyText" style="width:200px;" @change="getList" placeholder="模糊查询"></el-input> <el-button type="primary" @click="getList">搜索</el-button>
    <vxe-table :data="tableData" border :loading="loading" style="margin-top:10px;">
      <vxe-column field="serialNumber" title="序列号"></vxe-column>
      <vxe-column field="xing7" title="是否允许使用7型">
        <template #default="{row}">
          <vxe-switch open-label="是" v-model="row.xing7" close-label="否" @change="saveRow(row)"></vxe-switch>
        </template></vxe-column>
      <vxe-column field="xing8" title="是否允许使用8型">
        <template #default="{row}">
          <vxe-switch open-label="是" v-model="row.xing8" close-label="否" @change="saveRow(row)"></vxe-switch>
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-pager align="left" @page-change="getList" :page-size.sync="searchObj.pageSize" :current-page.sync="searchObj.currentPage" :total="searchObj.total" :layouts="[ 'PrevPage', 'Jump', 'PageCount', 'NextPage',  'Total']">
    </vxe-pager>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      tableData: [],
      searchObj: {
        onlyText: "",
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  methods: {
    saveRow(v) {
      let that = this;
      that.axios.post("PZD801/UpdateDeviceConfig", v).then((response) => {
        if (response.data.code == 101) {
          that.$message({ type: "success", message: "保存成功" });
        }
      });
    },
    getList() {
      let that = this;
      that.loading = true;
      that.axios.post("PZD801/GetDeviceConfigList", that.searchObj).then((response) => {
        that.tableData = response.data.data.data;
        that.searchObj.total = response.data.data.total;
        that.loading = false;
      });
    },
  },
  mounted() {
    let that = this;
    that.getList();
  },
};
</script>

<style>
</style>